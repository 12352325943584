/* eslint-disable */

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  ContentHeader,
  Title,
  Container,
  ContentBody,
  Metadata,
  TopFields,
  MiddleFields,
  BottomFields,
  Menus,
  MenuUrl,
  TopMenu,
  ProfileUserAvatarContainer,
  AvatarContainer, EditIconButton, AdditionalImages, DeleteIconButton
} from './Restaurant.styled';
import { useAppSelector } from '../../redux/hooks';
import { fetchRestaurantByAlias, loadReview, selectCurrentRestaurant } from '../Restaurant/slice';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useAppDispatch } from '../../redux/hooks';
import { selectIsLoading, deleteRestaurant, publishRestaurant, saveRestaurant, selectShouldOpen, selectMessage, setOpen, selectSeverity } from './slice';
import TextField from '@mui/material/TextField';
import { Restaurant, Restaurant as RestaurantDTO, RestaurantImage } from './types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Backdrop from '@mui/material/Backdrop';
import { Avatar } from '@mui/material';
import { IconPencil } from '@tabler/icons-react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../../redux/store';
import { okaoAxios } from '../../helpers/axios';

const RestaurantPage: React.FC = () => {
  const { alias } = useParams();
  const dispatch = useAppDispatch();
  const restaurantFromServer = useAppSelector(selectCurrentRestaurant);
  const [restaurant, setRestaurant] = useState(restaurantFromServer);
  useEffect(()=>{
    setRestaurant(restaurantFromServer)
  },[
    restaurantFromServer
  ])

  useEffect(() => {
      dispatch(fetchRestaurantByAlias(alias??''));
  }, [dispatch])


  const navigate = useNavigate();
  const navigateToMenu = (menuId: string): void => {
    navigate(`/restaurant/${alias}/menu/${menuId}`);
  }

  useEffect(() => {
    setName(restaurant?.name??'');
    setAddress1(restaurant?.address1??'');
    setAddress2(restaurant?.address2??'');
    setAddress3(restaurant?.address3??'');
    setCity(restaurant?.city??'');
    setState(restaurant?.state??'');
    setCountry(restaurant?.country??'');
    setPhone(restaurant?.phone??'');
    setWebsite(restaurant?.website??'');
    setReservationUrl(restaurant?.reservationUrl??'');
    setZipCode(restaurant?.zip_code??'');
    setContactName(restaurant?.contact_name??'');
    setContactEmail(restaurant?.contact_email??'');
    setTags(restaurant?.tags??'');
    setDescription(restaurant?.description??'');
    setFacebook(restaurant?.facebook??'');
    setInstagram(restaurant?.instagram??'');
    setAtmosphere(restaurant?.atmosphere??'');
    setCategory(restaurant?.category??'');
    setCuisine(restaurant?.cuisine??'');
    setOccasion(restaurant?.occasion??'');
    setSpecialFeatures(restaurant?.specialFeatures??'');
    setAwards(restaurant?.awards??'');
    setKnownFor(restaurant?.knownFor??'');    
  }, [restaurant]);
  
    const [ name, setName] = useState(restaurant?.name??'');
    const [ address1, setAddress1 ] = useState(restaurant?.address1??'');
    const [ address2, setAddress2 ] = useState(restaurant?.address2??'');
    const [ address3, setAddress3 ] = useState(restaurant?.address3??'');
    const [ city, setCity ] = useState(restaurant?.city??'');
    const [ state, setState ] = useState(restaurant?.state)??'';
    const [ country, setCountry ] = useState(restaurant?.country??'');
    const [ phone, setPhone ] = useState(restaurant?.phone??'');
    const [ website, setWebsite ] = useState(restaurant?.website??'');
    const [ reservationUrl, setReservationUrl] = useState(restaurant?.reservationUrl??'')
    const [ zipCode, setZipCode ] = useState(restaurant?.zip_code??'');
    const [ contactName, setContactName ] = useState(restaurant?.contact_name??'');
    const [ contactEmail, setContactEmail ] = useState(restaurant?.contact_email??'');
    const [ tags, setTags ] = useState(restaurant?.tags??'');
    const [ description, setDescription ] = useState(restaurant?.description??'');
    const [ instagram, setInstagram ] = useState(restaurant?.instagram??'');
    const [ facebook, setFacebook ] = useState(restaurant?.facebook??'');
    const [ atmosphere, setAtmosphere ] = useState(restaurant?.atmosphere??'');
    const [ category, setCategory ] = useState(restaurant?.category??'');
    const [ cuisine, setCuisine ] = useState(restaurant?.cuisine??'');
    const [ occasion, setOccasion ] = useState(restaurant?.occasion??'');
    const [ specialFeatures, setSpecialFeatures ] = useState(restaurant?.specialFeatures??'');
    const [ awards, setAwards ] = useState(restaurant?.awards??'');
    const [ knownFor, setKnownFor ] = useState(restaurant?.knownFor??'');
    const [ verified, setVerified ] = useState(restaurant?.verified??'');
    const[ mainImageUrl, setMainImageUrl] = useState(restaurant?.imageurl);
    const [images, setImages] = useState(() =>
      restaurant?.restaurantImages
        ? [...restaurant.restaurantImages].sort((a, b) => a.order - b.order)
        : []
    );


    const openServer = useAppSelector(selectShouldOpen);
    const message = useAppSelector(selectMessage);
    const severity = useAppSelector(selectSeverity);
    const isLoading = useAppSelector(selectIsLoading);

    const setOpenServer = (open: boolean) => {
      dispatch(setOpen(open));
    }

  const fileInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef0 = useRef<HTMLInputElement>(null)
  const fileInputRef1 = useRef<HTMLInputElement>(null)
  const fileInputRef2 = useRef<HTMLInputElement>(null)
  const fileInputRef3 = useRef<HTMLInputElement>(null)
  const handleClick = (): void => {
    fileInputRef.current?.click()
  }

  const handleClick0 = (): void => {
    fileInputRef0.current?.click()
  }

  const handleClick1 = (): void => {
    fileInputRef1.current?.click()
  }

  const handleClick2 = (): void => {
    fileInputRef2.current?.click()
  }

  const handleClick3 = (): void => {
    fileInputRef3.current?.click()
  }

  const updateRestaurantImage = createAsyncThunk(
    'api/updateRestaurantImage',
    async (formData: FormData) => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${restaurant?.alias}/main`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const updateRestaurantOtherImage = createAsyncThunk(
    'api/updateRestaurantOtherImage',
    async (formData: FormData) => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).post(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${restaurant?.restaurantID}/other/${formData.get('index')}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const deleteRestaurantOtherImage = createAsyncThunk(
    'api/deleteRestaurantOtherImage',
    async (imageIndex: number) => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).delete(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${restaurant?.restaurantID}/other/${imageIndex}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const deleteRestaurantImage = createAsyncThunk(
    'api/deleteRestaurantImage',
    async () => {
      const auth = store.getState().secure.accessToken ?? '';
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const response = await okaoAxios(auth).delete(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${restaurant?.alias??''}/main`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const data = {
        ...response.data,
        headers: { ...response.headers },
      }
      return data
    },
  )

  const handleDeleteImage = async (event: any, imageIndex: number | undefined): Promise<void> => {
    if(imageIndex !== undefined){
      const result = await dispatch(deleteRestaurantOtherImage(imageIndex))
      if (deleteRestaurantOtherImage.fulfilled.match(result)) {
        setImages(
          result.payload?.restaurantImages
            ? [...result.payload?.restaurantImages].sort((a, b) => a.order - b.order)
            : []
        )
      }
    }else{
      const result = await dispatch(deleteRestaurantImage())
      if (deleteRestaurantImage.fulfilled.match(result)) {
        setMainImageUrl('')
      }
    }
  }

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    imageIndex: number | undefined
  ): Promise<void> => {
    if ((event?.target?.files) != null) {
      const file = event?.target?.files?.[0]

      const formData = new FormData()
      formData.append('file', file)
      if(imageIndex !== undefined){
        formData.append('index', imageIndex.toString())
        const result = await dispatch(updateRestaurantOtherImage(formData))
        if (updateRestaurantOtherImage.fulfilled.match(result)) {
          setImages(
            result.payload?.restaurantImages
              ? [...result.payload?.restaurantImages].sort((a, b) => a.order - b.order)
              : []
          )
        }
      }else {
        const result = await dispatch(updateRestaurantImage(formData))
        if (updateRestaurantImage.fulfilled.match(result)) {
          setMainImageUrl(result.payload.imageUrl)
        }
      }
    }
  }
    return (
      <PageContainer title={restaurant?.name} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        {/*<CircularProgress color="inherit" />*/}
      </Backdrop>
        <Container>
          <ContentHeader>
            <Title>Details</Title>
            <TopMenu>
              <Stack direction="row" spacing={2}>
                <Button variant="text" onClick={() => {
                  const request: RestaurantDTO = {
                    restaurantID: restaurant?.restaurantID??'',
                    alias: restaurant?.alias??'',
                    name: name??'',
                    address1: address1??'',
                    address2: address2,
                    address3: address3,
                    city: city??'',
                    state: state??'',
                    country: country??'',
                    phone: phone,
                    website: website,
                    reservationUrl: reservationUrl??'',
                    zip_code: zipCode,
                    contact_name: contactName,
                    contact_email: contactEmail,
                    place_id: restaurant?.place_id??'',
                    tags: tags,
                    description: description,
                    instagram: instagram,
                    facebook: facebook,
                    status: restaurant?.status??'',
                    menus: restaurant?.menus,
                    atmosphere: atmosphere,
                    category: category,
                    cuisine: cuisine,
                    occasion: occasion,
                    specialFeatures: specialFeatures,
                    awards: awards,
                    knownFor: knownFor
                  }
                  // eslint-disable-next-line
                  dispatch(saveRestaurant(request));
                }}>Update</Button>
                <Button variant='text' onClick={() => {
                    if(restaurant){
                      dispatch(publishRestaurant(restaurant.restaurantID));
                    }
                  }}>Publish</Button>
                  <Button variant='text' onClick={() => {
                    if(restaurant){
                      dispatch(deleteRestaurant(restaurant.restaurantID));
                      navigate('/');
                    }
                  }}>Delete</Button>
                  <Button variant='text' onClick={() => {
                    if(restaurant){
                      dispatch(loadReview(restaurant.restaurantID));
                    }
                  }}>Load Reviews</Button>
                  <Button variant='text' onClick={() => {
                    navigate(`/restaurant/${restaurant?.alias}/add-new-menu`);
                  }}>Add Menu</Button>
                  <Button variant='text' onClick={() => {
                    navigate(`/restaurant/${restaurant?.alias}/reviews`);
                  }}>Reviews</Button>
              </Stack>
            </TopMenu>
          </ContentHeader>
          <ContentBody>
            <ProfileUserAvatarContainer>
              <AvatarContainer>
                <Avatar
                  src={
                    mainImageUrl ??
                    mainImageUrl ??
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                  sx={{
                    width: 150,
                    height: 150,
                    display: 'block'
                  }}
                  alt="Profile Image"
                />
                <EditIconButton onClick={handleClick}>
                  <IconPencil />
                </EditIconButton>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    void handleFileChange(event, undefined);
                  }}
                />
                <DeleteIconButton onClick={(event) => {
                  void handleDeleteImage(event, undefined);
                }} />
              </AvatarContainer>
            </ProfileUserAvatarContainer>
            <Metadata>
              <TopFields>
                <Stack direction="row" spacing={2}>
                  <TextField id="name" label="Name" variant="outlined" value={name}
                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                               setName(event.target.value);
                    }}/>
                    <TextField id="address1" label="Address Line 1" variant="outlined" value={address1} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddress1(event.target.value);
                    }}/>
                    <TextField id="address2" label="Address Line 2" variant="outlined" value={address2} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddress2(event.target.value);
                    }}/>
                    <TextField id="address3" label="Address Line 3" variant="outlined" value={address3} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAddress3(event.target.value);
                    }}/>
                    <TextField id="city" label="City" variant="outlined" value={city} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCity(event.target.value);
                    }}/>
                    <TextField id="state" label="State" variant="outlined" value={state} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setState(event.target.value);
                    }}/>
                    <TextField id="zipCode" label="Zip Code" variant="outlined" value={zipCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setZipCode(event.target.value);
                    }}/>
                    <TextField id="country" label="Country" variant="outlined" value={country} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCountry(event.target.value);
                    }}/>
                  </Stack>
                </TopFields>
                <MiddleFields>
                  <Stack direction="row" spacing={2}>
                  <TextField id="contactName" label="Contact Name" variant="outlined" value={contactName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setContactName(event.target.value);
                    }}/>
                    <TextField id="contactEmail" label="Contact Email" variant="outlined" value={contactEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setContactEmail(event.target.value);
                    }}/>
                    <TextField id="phone" label="Phone" variant="outlined" value={phone} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(event.target.value);
                    }}/>
                    <TextField id="website" label="Website" variant="outlined" value={website} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setWebsite(event.target.value);
                    }}/>
                    <TextField id="reservationUrl" label="Reservation URL" variant="outlined" value={reservationUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setReservationUrl(event.target.value);
                    }}/>
                    <TextField id="created" label="Created" disabled variant="standard" value={restaurant?.created} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      //do nothing
                    }}/>
                    <TextField id="updated" label="Updated" disabled variant="standard" value={restaurant?.updated} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      //do nothing
                    }}/>
                  </Stack>
                </MiddleFields>
                <MiddleFields>
                  <Stack direction="row" spacing={2}>
                  <TextField id="atmosphere" label="Atmosphere" variant="outlined" value={atmosphere} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAtmosphere(event.target.value);
                    }}/>
                    <TextField id="category" label="Category" variant="outlined" value={category} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCategory(event.target.value);
                    }}/>
                    <TextField id="cuisine" label="Cuisine" variant="outlined" value={cuisine} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCuisine(event.target.value);
                    }}/>
                    <TextField id="occasion" label="Occasion" variant="outlined" value={occasion} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setOccasion(event.target.value);
                    }}/>
                    <TextField id="special_features" label="Special Features" variant="outlined" value={specialFeatures} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSpecialFeatures(event.target.value);
                    }}/>
                    <TextField id="awards" label="Awards" variant="outlined" value={awards} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAwards(event.target.value);
                    }}/>
                    <TextField id="known_for" label="Known For" variant="outlined" value={knownFor} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setKnownFor(event.target.value);
                    }}/>
                  </Stack>
                </MiddleFields>
                <BottomFields>
                  <Stack direction="row" spacing={4} sx={{width: '100%'}}>
                    <TextField id="description" sx={{width: '35rem'}} label="Description" multiline rows={4} value={description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDescription(event.target.value);
                    }}/>
                    <TextField id="tags" label="Tags" sx={{width: '15rem'}} multiline fullWidth variant="outlined" value={tags} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTags(event.target.value);
                    }}/>
                    <TextField id="instagram" label="Instagram" variant="outlined" value={instagram} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInstagram(event.target.value);
                    }}/>
                    <TextField id="facebook" label="Facebook" variant="outlined" value={facebook} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFacebook(event.target.value);
                    }}/>
                  </Stack>
                </BottomFields>
            </Metadata>
            <Menus>
              <Stack direction="row" spacing={2} sx={{flexWrap: "wrap"}}>
                {restaurant?.menus?.map(menu => {
                  return (
                    <Card key={menu.menuID} sx={{ width: 300}}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {menu.name}
                        </Typography>
                        <Typography variant="body2">
                          <MenuUrl>
                            {menu.url}
                          </MenuUrl>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={() => {
                          navigateToMenu(menu.menuID);
                        }}>View</Button>
                      </CardActions>
                    </Card>
                  )
                })}
              </Stack>
            </Menus>
            <AdditionalImages>
              <ProfileUserAvatarContainer>
                <AvatarContainer>
                  <Avatar
                    src={
                      images[0] ?
                        (images[0] as RestaurantImage).url :
                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    }
                    sx={{
                      width: 100,
                      height: 100,
                      display: 'block'
                    }}
                    alt="Profile Image"
                  />
                  <EditIconButton onClick={handleClick0}>
                    <IconPencil />
                  </EditIconButton>
                  <input
                    ref={fileInputRef0}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      void handleFileChange(event, 0);
                    }}
                  />
                  {/* eslint-disable-next-line */}
                  <DeleteIconButton onClick={(event) => {
                    void handleDeleteImage(event, 0);
                  }} />
                </AvatarContainer>
              </ProfileUserAvatarContainer>
              <ProfileUserAvatarContainer>
                <AvatarContainer>
                <Avatar
                    src={
                      images[1] ?
                        (images[1] as RestaurantImage).url :
                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    }
                    sx={{
                      width: 100,
                      height: 100,
                      display: 'block'
                    }}
                    alt="Profile Image"
                  />
                  <EditIconButton onClick={handleClick1}>
                    <IconPencil />
                  </EditIconButton>
                  <input
                    ref={fileInputRef1}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      void handleFileChange(event, 1);
                    }}
                  />
                  {/* eslint-disable-next-line */}
                  <DeleteIconButton onClick={(event) => {
                    void handleDeleteImage(event, 1);
                  }} />
                </AvatarContainer>
              </ProfileUserAvatarContainer>
              <ProfileUserAvatarContainer>
                <AvatarContainer>
                  <Avatar
                    src={
                      images[2] ?
                        (images[2] as RestaurantImage).url :
                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    }
                    sx={{
                      width: 100,
                      height: 100,
                      display: 'block'
                    }}
                    alt="Profile Image"
                  />
                  <EditIconButton onClick={handleClick2}>
                    <IconPencil />
                  </EditIconButton>
                  <input
                    ref={fileInputRef2}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      void handleFileChange(event, 2);
                    }}
                  />
                  {/* eslint-disable-next-line */}
                  <DeleteIconButton onClick={(event) => {
                    void handleDeleteImage(event, 2);
                  }} />
                </AvatarContainer>
              </ProfileUserAvatarContainer>
              <ProfileUserAvatarContainer>
                <AvatarContainer>
                  <Avatar
                    src={
                      images[3] ?
                        (images[3] as RestaurantImage).url :
                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                    }
                    sx={{
                      width: 100,
                      height: 100,
                      display: 'block',
                    }}
                    alt="Profile Image"
                  />
                  <EditIconButton onClick={handleClick3}>
                    <IconPencil />
                  </EditIconButton>
                  <input
                    ref={fileInputRef3}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      void handleFileChange(event, 3);
                    }}
                  />
                  {/* eslint-disable-next-line */}
                  <DeleteIconButton onClick={(event) => {
                    void handleDeleteImage(event, 3);
                  }} />
                </AvatarContainer>
              </ProfileUserAvatarContainer>
            </AdditionalImages>
          </ContentBody>
        </Container>
      </PageContainer>
    )
}

export default withAuthenticationRequired(RestaurantPage)
