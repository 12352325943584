import React, { ChangeEvent, useRef } from 'react';
import { Avatar, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import { IconPencil } from '@tabler/icons-react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { useAppDispatch } from '../../../redux/hooks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { okaoAxios } from '../../../helpers/axios';
import { store } from '../../../redux/store';

import { Dish } from '../../../pages/Menu/types';
import { AdminRestaurant } from '../../../pages/Restaurant/types';

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '20rem',
});

const EditIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: -17,
  backgroundColor: 'white',
  padding: 9,
  boxShadow: '0px 0px 4px 0px #33333340',
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
});

const DeleteIconButton = styled(DeleteIcon)({
  position: 'absolute',
  top: 0,
  left: 100,
  backgroundColor: 'white',
  padding: 9,
  boxShadow: '0px 0px 4px 0px #33333340',
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
});

export interface AvatarCellProps {
  params: GridRenderCellParams<Dish | AdminRestaurant>;
  setRows: React.Dispatch<React.SetStateAction<Array<Dish | AdminRestaurant>>>;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function AvatarCell({ params, setRows }: AvatarCellProps) {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const updateDishImage = createAsyncThunk(
    'api/updateDishImage',
    async (formData: FormData) => {
      if ('dishID' in params.row) {
        const auth = store.getState().secure.accessToken ?? '';
        const response = await okaoAxios(auth).post(
          `${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/dish/${params.row.dishID}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return { ...response.data };
      }
    }
  );

  const deleteDishImage = createAsyncThunk(
    'api/deleteDishImage',
    async () => {
      if ('dishID' in params.row) {
        const auth = store.getState().secure.accessToken ?? '';
        const response = await okaoAxios(auth).delete(
          `${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/dish/${params.row.dishID}`,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return { ...response.data };
      }
    }
  );

  const updateRestaurantImage = createAsyncThunk(
    'api/updateRestaurantImage',
    async (formData: FormData) => {
      if ('alias' in params.row) {
        const auth = store.getState().secure.accessToken ?? '';
        const response = await okaoAxios(auth).post(
          `${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${params.row.alias}/main`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return { ...response.data };
      }
    }
  );

  const deleteRestaurantImage = createAsyncThunk(
    'api/deleteRestaurantImage',
    async () => {
      if ('alias' in params.row) {
        const auth = store.getState().secure.accessToken ?? '';
        const response = await okaoAxios(auth).delete(
          `${process.env.REACT_APP_DIS_URL ?? window.location.origin}/upload/restaurant/${params.row.alias}/main`,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return { ...response.data };
      }
    }
  );

  const handleClick = (): void => {
    fileInputRef.current?.click();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if ((event.target?.files?.[0]) != null) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      if ('price' in params.row) {
        const result = await dispatch(updateDishImage(formData));
        // eslint-disable-next-line
        if (updateDishImage.fulfilled.match(result) && result.payload?.imageUrl) {
          const newUrl = result.payload.imageUrl;
          setRows((oldRows) =>
            oldRows.map((r) =>
              (r as Dish).dishID === (params.row as Dish).dishID
                ? { ...r, imageurl: newUrl }
                : r
            )
          );
        }
      } else {
        const result = await dispatch(updateRestaurantImage(formData));
        // eslint-disable-next-line
        if (updateRestaurantImage.fulfilled.match(result) && result.payload?.imageUrl) {
          // eslint-disable-next-line
          const newUrl = result.payload.imageUrl;
          setRows((oldRows) => {
            const updatedRows = oldRows.map((r) =>
              (r as AdminRestaurant).restaurantID === (params.row as AdminRestaurant).restaurantID ? { ...r, imageurl: newUrl } : r
            );
            return updatedRows;
          });
        }
      }
    }
  };

  const handleDeleteImage = async (): Promise<void> => {
    if ('price' in params.row) {
      const result = await dispatch(deleteDishImage());
      if (deleteDishImage.fulfilled.match(result)) {
        setRows((oldRows) =>
          oldRows.map((r) =>
            (r as Dish).dishID === (params.row as Dish).dishID
              ? {
                ...r,
                imageurl:
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
              }
              : r
          )
        );
      }
    } else {
      const result = await dispatch(deleteRestaurantImage());
      if (deleteRestaurantImage.fulfilled.match(result)) {
        setRows((oldRows) =>
          oldRows.map((r) =>
            (r as AdminRestaurant).restaurantID === (params.row as AdminRestaurant).restaurantID
              ? {
                ...r,
                imageurl:
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
              }
              : r
          )
        );
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!params.row) return null;

  return (
    <AvatarContainer>
      <div>
        <Avatar
          src={
            params.row.imageurl ??
            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
          }
          sx={{ width: 35, height: 35, display: 'inline-block' }}
          alt="Profile Image"
        />
      </div>
      <div>
        <EditIconButton onClick={handleClick}>
          <IconPencil size={18} stroke={2} />
        </EditIconButton>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onChange={handleFileChange}
      />
      <div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <DeleteIconButton onClick={handleDeleteImage} />
      </div>
    </AvatarContainer>
  );
}