import React from "react";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import {
    GridRowModes,
    GridToolbarContainer,
    GridRowsProp,
    GridRowModesModel,
    GridToolbarProps
} from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectCurrentPage, generateTagsAndDescriptions, publishAllRestaurants, loadReviews, fetchRestaurantsAdminDashboard, generateRestaurantImages, generateDishImages } from "../../pages/Restaurant/slice";

interface EditToolbarProps extends GridToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    empty: any;
    shouldNavigate?: boolean
    route?: string
    dashboard?: boolean
}

export const EditToolbar = (props: EditToolbarProps): JSX.Element => {
    const { setRows, setRowModesModel, empty, shouldNavigate, route, dashboard } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentPage = useAppSelector(selectCurrentPage)
  
    const handleClick = (): void => {
      empty.fields.isNew = true;
      setRows((oldRows) => [...oldRows, empty.fields]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [empty.id]: { mode: GridRowModes.Edit, fieldToFocus: empty.focusField },
      }));
    };
  
    return (
      <GridToolbarContainer>
        {/* eslint-disable-next-line */}
        {(!dashboard && !shouldNavigate) && <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>}
        {/* eslint-disable-next-line */}
        {(!dashboard && shouldNavigate) && <Button color="primary" startIcon={<AddIcon />} onClick={() => {navigate(route??'/')}}>
          Add
        </Button>}
        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(generateTagsAndDescriptions())}}>
          Generate Tags & Descriptions
        </Button>}

        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(generateRestaurantImages())}}>
          Generate Restaurant Images
        </Button>}

        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(generateDishImages())}}>
          Generate Dish Images
        </Button>}

        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(publishAllRestaurants())}}>
          Publish All
        </Button>}
        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(loadReviews())}}>
          Load Reviews
        </Button>}
        {/* eslint-disable-next-line */}
        {dashboard && <Button color="primary" onClick={() => {dispatch(fetchRestaurantsAdminDashboard(currentPage))}}>
          Refresh
        </Button>}
      </GridToolbarContainer>
    );
  }