import React, {useEffect, useState} from 'react'
import {
    Container,
    ContentContainer,
    Header,
    MenuTitle,
    Actions,
    // LeftActions,
    // RightActions,
    GridContainer
} from './DishGrid.styled'

import { 
  GridColDef
} from '@mui/x-data-grid';
import {EditToolbar} from '../EditToolbar/EditToolbar'
import Grid from '../Grid/Grid';
import { GridRowProps } from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {Menu, Dish, DishRequest} from '../../pages/Menu/types';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import {saveDish, deleteDish, selectPageSize, setPageSize} from '../../pages/Menu/slice'

// eslint-disable-next-line
type Props = {
    menu?: Menu
  };

const DishGrid: React.FC<Props> = ({
    menu
}: Props): JSX.Element => {
  useEffect(() => {
    setDishes(menu?.dishes??[]);
  }, [menu]);
  
  const [dishes, setDishes] = useState(menu?.dishes??[]);
  const dispatch = useAppDispatch();
  const pageSize = useAppSelector(selectPageSize);
  const rows: GridRowProps[] = dishes.map(dish => {
    return {
            id: dish.dishID,
            dishID: dish.dishID,
            imageurl: dish.imageurl,
            name: dish.name,
            description: dish.description,
            price: dish.price,
            ingredients: dish.ingredients,
            tags: dish.tags,
            status: dish.status,
            dietary: dish.dietary,
            unsuitabledietary: dish.unsuitabledietary,
            preparation: dish.preparation,
            created: dish.created,
            updated: dish.updated
          }
  })??[];
  const id = randomId()
  const empty = {focusField: 'name', fields: { 
    id,
    dishID: id,
      imageurl: '',
    name: '',
    description: '',
    price: '',
    ingredients: '',
    created: '',
    tags: '',
    updated: '',
    status: '',
    dietary: '',
    unsuitabledietary: '',
    preparation: ''
  }
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Dish Name', width: 250 },
    { field: 'description', headerName: 'Description', width: 250, editable: true  },
    { field: 'ingredients', headerName: 'Ingredients', width: 250, editable: true },
    { field: 'preparation', headerName: 'Preparation', width: 150, editable: true},
    { field: 'price', headerName: 'Price', width: 100, editable: true },
    { field: 'dietary', headerName: 'Dietary', width: 150, editable: true},
    { field: 'unsuitabledietary', headerName: 'UnsuitableDietary', width: 150, editable: true},
    { field: 'status', headerName: 'Status', width: 100},
    { field: 'tags', headerName: 'Tags', width: 250, editable: true },
  ];

  const getRowID = (row: Dish): string => {
    return row.dishID;
  }

  // eslint-disable-next-line
  const saveFn = (row: Dish) => {
    const dishRequest: DishRequest = {
      menuId: menu?.menuID??'',
      dishId: row.dishID,
      name: row.name,
      price: row.price,
      imageurl: row.imageurl,
      description: row.description,
      tags: row.tags,
      ingredients: row.ingredients,
      dietary: row.dietary,
      unsuitabledietary: row.unsuitabledietary,
      preparation: row.preparation
    }
    
      // eslint-disable-next-line
      dispatch(saveDish(dishRequest));
  }

  const deleteFn = (rowId: string): void => {
    // eslint-disable-next-line
    dispatch(deleteDish(rowId));
  }

  return (
    <Container>
      <ContentContainer>
        <Header>
          <MenuTitle>{menu?.name}</MenuTitle>
          <Actions>
            {/* <LeftActions/> */}
            {/* <RightActions>
            </RightActions> */}
          </Actions>
        </Header>
        <GridContainer>
          <Grid 
              rows={rows} 
              setRows={setDishes}
              totalRowCount={menu?.dishes.length??0}
              dataColumns={columns} 
              EditToolbar={EditToolbar}
              empty={empty}
              getRowId={getRowID}
              saveFn={saveFn}
              deleteFn={deleteFn}
              paginationServer={false}
              rowClickedFn={null}
              nextPageFn={null}
              editable={true}
              pageSize={pageSize}
              pageSizeOptions={[25,50,100]}
              setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
              />
          </GridContainer>
      </ContentContainer>
    </Container>
  );
};

export default DishGrid;
