/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from './Eaters.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectEaterByEaterId,
  selectRowCount,
  selectShouldOpen,
  selectMessage,
  selectSeverity,
  setOpen,
  setPageSize,
  selectPageSize,
  selectEater
} from './slice';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import {
  GridColDef,
  GridEventListener,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { DiningReview, Eater } from './types'
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import { selectRestaurantWithMenuByAlias } from '../Restaurant/slice';

const DiningReviews: React.FC = () => {
  const openServer = useAppSelector(selectShouldOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {eaterid} = useParams();
  const person = useAppSelector(selectEater);
  const [reviews, setReviews] = useState(person?.reviews);
  const rowCount = person?.reviews?.length??0;
  const pageSize = useAppSelector(selectPageSize);

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: 'Eaters',
      url: '/eaters'
    },
    {
      name: person?.firstName + " " + person?.lastName??'', 
      url:`/eaters/${person?.eaterId}`
    }
  ]


  const handleReviewClick: GridEventListener<'rowClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => {
    navigate(`/eaters/${eaterid}/dining-reviews/${params.row.diningReviewId}`)
  };

  const rows: GridRowProps[] = reviews?.map(review => {
    return {
            id: review.diningReviewId,
            diningReviewId: review.diningReviewId,
            restaurantId: review.restaurantAlias,
            restaurantName: useAppSelector(selectRestaurantWithMenuByAlias(review.restaurantAlias))?.name,
            dishId: review.dishId,
            dishName: useAppSelector(selectRestaurantWithMenuByAlias(review.restaurantAlias))?.menus?.find(menu => menu.menuID === review.menuId)?.dishes.find(dish => dish.dishID === review.dishId)?.name,
            value: review.value,
            sentimentLabel: review.sentimentLabel,
            sentimentScore: review.sentimentScore,
            sentimentSummary: review.sentimentSummary,
            sentimentTags: review.sentimentTags,
            created: review.created,
            updated: review.updated,
          }
  })??[]
  
  const columns: GridColDef[] = [
    { field: 'restaurantName', headerName: 'Restaurant Name', width: 300 },
    { field: 'dishName', headerName: 'Dish Name', width: 250 },
    { field: 'sentimentLabel', headerName: 'Sentiment Label', width: 300},
    { field: 'sentimentScore', headerName: 'Sentiment Score', width: 150, editable: true},
  ];

  const id = randomId()
  const empty = {focusField: 'restaurantName', fields: { 
    id: id,
    diningReviewId: id,
    restaurantId: '',
    restaurantName: '',
    dishId: '',
    dishName: '',
    value: '',
    sentimentLabel: '',
    sentimentScore: '',
    sentimentSummary: '',
    sentimentTags: '',
    created: '',
    updated: '',
  }
  }

  const getRowID = (row: DiningReview): string => {
    return row.diningReviewId;
  }
    
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Dining Reviews' breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={setReviews}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={false}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => {/** do nothing*/}}
            rowClickedFn={handleReviewClick}
            deleteFn={(id: string) => {/* do nothing */}}
            editable={false}
            pageSize={pageSize}
            saveFn={(newRow) => {
              /* do nothing */
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {setPageSize(pageSize)}}
            dashboard={false}
            shouldNavigate={true}
            route={`/eaters/${person?.eaterId}/dining-reviews/add-dining-review`}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(DiningReviews)
