/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Container } from './Eaters.styled';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchEaters, setPageSize, selectPageSize, selectRowCount, selectShouldOpen, selectEaters, selectMessage, selectSeverity, setOpen, blockUser } from './slice';
import PageContainer from '../../components/PageContainer/PageContainer';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
  GridColDef,
  GridEventListener,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails
} from '@mui/x-data-grid';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid'
import { Eater } from './types'
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import { fetchRestaurantsWithMenus } from '../Restaurant/slice';
import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';

const Eaters: React.FC = () => {
  const openServer = useAppSelector(selectShouldOpen);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eatersFromServer = useAppSelector(selectEaters);
  const pageSize = useAppSelector(selectPageSize)
  const [eaters, setEaters] = useState(eatersFromServer);
  const rowCount = useAppSelector(selectRowCount);

  
  useEffect(()=>{
    setEaters(eatersFromServer)
  },[
    eatersFromServer
  ])

  const handleEaterClick: GridEventListener<'rowClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains('block-button')) {
      event.defaultMuiPrevented = true;
      return;
    }
    navigate(`/eaters/${params.row.eaterId}`)
  };

  useEffect(() => {
    dispatch(fetchEaters(0));
  }, [dispatch])

  const rows: GridRowProps[] = eaters.map(eater => {
    return {
            id: eater.eaterId,
            eaterId: eater.eaterId,
            firstName: eater.firstName,
            lastName: eater.lastName,
            email: eater.email,
            dob: eater.dob,
            phone: eater.phone,
            addressLine1: eater.addressLine1,
            addressLine2: eater.addressLine2,
            reviewCount: eater.reviews?.length,
            city: eater.city,
            state: eater.state,
            zipcode: eater.zipcode,
            country: eater.country,
            instagram: eater.instagram,
            facebook: eater.facebook,
            twitter: eater.twitter,
            tiktok: eater.tiktok,
            persona: eater.persona,
            cuisineLikes: eater.cuisineLikes,
            cuisineDislikes: eater.cuisineDislikes,
            ingredientLikes: eater.ingredientLikes,
            ingredientDislikes: eater.ingredientDislikes,
            favoriteReviewers: eater.favoriteReviewers,
            created: eater.created,
            updated: eater.updated,
            gender: eater.gender,
            incomeLevel: eater.incomeLevel,
            diningFrequency: eater.diningFrequency,
            typeOfDiningEstablishment: eater.typeOfDiningEstablishment,
            dietaryPreferences: eater.dietaryPreferences,
            allergies: eater.allergies,
            socialMediaActivity: eater.socialMediaActivity,
            favoriteRestaurants: eater.favoriteRestaurants,
            status: eater.status
          }
  })

  const handleBlockUser = (id: string): void => {
    try {
      dispatch(blockUser(id))
      .then(() => {
        dispatch(fetchEaters(0));
      })
      .catch(err => err)
    } catch (e) {
      console.log(e);
    }
  }
  
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 300},
    { field: 'gender', headerName: 'Gender', width: 150, editable: true},
    { field: 'reviewCount', headerName: 'Review Count', width: 100},
    { field: 'favoriteRestaurants', headerName: 'Favorite Restaurants', width: 250, editable: true},
    { field: 'updated', headerName: 'Updated Date', width: 150 },
    { field: 'block', headerName: 'Block User', width: 150, renderCell: (params: GridRenderCellParams): any => {
      if (params.row.status === "BLOCKED") {
        return <div style={{marginLeft: "8px"}}>BLOCKED</div>;
      }
      else {
        return <Button data-testid="block-button" variant="text" className='block-button' onClick={() => handleBlockUser(params.row.id)}>Block User</Button>
      }
    }}
  ];

  const id = randomId()
  const empty = {focusField: 'name', fields: { 
    id,
    eaterId: id,
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    phone: '',
    addressLine1:  '',
    addressLine2:  '',
    city:  '',
    state:  '',
    zipcode:  '',
    country:  '',
    instagram:  '',
    facebook:  '',
    twitter:  '',
    tiktok:  '',
    persona:  '',
    cuisineLikes:  '',
    cuisineDislikes:  '',
    ingredientLikes:  '',
    ingredientDislikes:  '',
    favoriteReviewers:  '',
    created:  '',
    updated:  '',
    gender:  '',
    incomeLevel:  '',
    diningFrequency:  '',
    typeOfDiningEstablishment:  '',
    dietaryPreferences:  '',
    allergies: '',
    socialMediaActivity: '',
    favoriteRestaurants: '',
  }
  }

  const getRowID = (row: Eater): string => {
    return row.eaterId;
  }
    
  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }

  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  return (
      <PageContainer title='Eaters' open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
        <Container>
          <Grid 
            rows={rows} 
            setRows={setEaters}
            totalRowCount={rowCount}
            dataColumns={columns}
            paginationServer={true}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            nextPageFn={(newPage: number) => dispatch(fetchEaters(newPage))}
            rowClickedFn={handleEaterClick}
            deleteFn={(id: string) => {/* do nothing */}}
            editable={false}
            pageSize={pageSize}
            saveFn={(newRow) => {
              /* do nothing */
            }}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            dashboard={false}
            shouldNavigate={true}
            route='/eaters/add-eater'
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(Eaters)
